import {
  useCallback,
  useEffect,
  Fragment,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ToggleHook, useToggle, useTooltipVisibility } from 'shared/hooks'
import EventDriver from 'services/event'
import { dateNowWithSeconds } from 'utilities/date'
import {
  Icon,
  Tags,
  Tooltip,
  TableV2 as Table,
  EmptyState,
  LoaderV2 as Loader,
  PaginationV2 as Pagination,
  Droplist,
} from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import {
  eventFiltersSchema,
  EventHistoryFiltersSchema,
} from 'domains/customer/schemas/event/searchEventSchema'
import { OccurrenceEvent } from 'services/event/types'
import { getEventDescription, getEventDevice } from 'utilities/event'
import { EventHistoryFilters } from '../EventHistoryFilters/EventHistoryFilters'
import styles from './styles.module.scss'
import { joiResolver } from '@hookform/resolvers/joi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getNormalizedDeviceTypeCode } from 'utilities/devices'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { paths, buildPath } from 'routes'

const EventDescription = ({ eventType, device, ...event }: OccurrenceEvent) => {
  const { ref, isTooltipVisible, handleMouseEnter, handleMouseLeave } =
    useTooltipVisibility()

  const iconVisibility = useToggle()
  const tooltipIcon = useRef(null)

  const description = getEventDescription(
    eventType?.code || '',
    eventType?.description || '',
    getNormalizedDeviceTypeCode(device?.deviceTypeCode),
    device?.sensorType || undefined,
  )

  return (
    <div className={styles.eventDescription}>
      {event.ignoredReason === 'MAINTENANCE_MODE' && (
        <>
          <div
            ref={tooltipIcon}
            onMouseEnter={iconVisibility.show}
            onMouseLeave={iconVisibility.hide}
          >
            <Icon
              name="system-maintenance"
              aria-label="Modo manutenção"
              color="accent-gray-medium"
              className={styles.icon}
              width={12}
              height={12}
            />
          </div>

          <Tooltip
            type="informative"
            isVisible={iconVisibility.isVisible}
            parentRef={tooltipIcon}
          >
            Central em manutenção
          </Tooltip>
        </>
      )}

      <small
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {description}

        {isTooltipVisible && (
          <Tooltip
            type="informative"
            isVisible={isTooltipVisible}
            parentRef={ref}
          >
            {description}
          </Tooltip>
        )}
      </small>

      {event.ignoredReason === 'INACTIVE_ACCOUNT' && (
        <span className={styles.inactiveAccount}>Inativa</span>
      )}
    </div>
  )
}

const OriginCell = ({ row }) => {
  const iconVisibility = useToggle()
  const tooltipIcon = useRef(null)

  const { auxiliary, originName, ...event } = row.original

  return (
    <>
      <div className={styles.originCell}>
        {event.ignoredReason === 'DISABLE_DEVICE' && (
          <div
            ref={tooltipIcon}
            onMouseEnter={iconVisibility.show}
            onMouseLeave={iconVisibility.hide}
          >
            <Icon
              name="blocked"
              aria-label="Modo manutenção"
              color="accent-gray-medium"
              className={styles.icon}
              width={12}
              height={12}
            />
          </div>
        )}

        <div>
          {`
          ${auxiliary?.code || ''} 
          ${originName && auxiliary?.code ? ' - ' : ' '} 
          ${originName || '--'}
        `}
        </div>
      </div>

      <Tooltip
        type="informative"
        isVisible={iconVisibility.isVisible}
        parentRef={tooltipIcon}
      >
        Dispositivo anulado
      </Tooltip>
    </>
  )
}

const PartitionsCell = ({ row }) => {
  const tooltip = useToggle()
  const tagRef = useRef<HTMLDivElement>(null)

  const partitions = row.original.partitions || []

  return (
    <>
      <div onMouseEnter={tooltip.show} onMouseLeave={tooltip.hide}>
        <Tags
          className={styles.firstTag}
          data={
            partitions?.map((partition) => ({
              name: partition.code,
            })) || []
          }
          hideDefaultTooltip
          ref={tagRef}
        />
      </div>

      {partitions?.length > 1 && (
        <Tooltip
          parentRef={tagRef}
          type="informative"
          isVisible={tooltip.isVisible}
        >
          {partitions
            .slice(1)
            .map((partition) => `${partition.code} - ${partition.name}`)
            .join(', ')}
        </Tooltip>
      )}
    </>
  )
}

const columnHelper = createColumnHelper<OccurrenceEvent>()

interface EventHistoryProps {
  filtersProps?: ToggleHook
  filteredAccount: string
  openInNewTab?: boolean
}

export const EventHistory = ({
  filtersProps,
  filteredAccount,
  openInNewTab,
}: EventHistoryProps) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isError, setIsError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState({ data: [], totalElements: 0 })

  const { getFilters, setFilters } =
    usePersistentFilters<EventHistoryFiltersSchema>('event-history')

  const form = useForm<EventHistoryFiltersSchema>({
    resolver: joiResolver(eventFiltersSchema),
    defaultValues: getFilters(),
  })

  const { watch, register, unregister, handleSubmit } = form

  const event = watch('event')
  const type = watch('type')
  const equipment = watch('equipment')
  const createdFrom = watch('createdFrom')
  const createdTo = watch('createdTo')

  const fetchEvents = useCallback(async () => {
    setIsFetching(true)

    const currentPage = Number(searchParams.get('page')) || 0
    const size = 15

    try {
      const events = await EventDriver.queryEvents({
        page: currentPage - 1,
        size,
        accountId: filteredAccount,
        ...(event && { eventCode: event.code }),
        ...(type && { occurrenceTypeId: type.id }),
        ...(equipment && { deviceTypeCode: equipment.code }),
        ...(createdFrom && { createdFrom }),
        ...(createdTo && { createdTo }),
      })
      setData(events)
    } catch {
      setIsError(true)
    } finally {
      setIsFetching(false)
    }
  }, [
    createdFrom,
    createdTo,
    equipment,
    event,
    type,
    filteredAccount,
    searchParams,
  ])

  const page = searchParams.get('page')
  const filter = searchParams.get('filter')

  useEffect(() => {
    if (page || filter) {
      fetchEvents()
    }
  }, [page, filter])

  useEffect(() => {
    register('event')
    register('equipment')
    register('type')
    register('createdFrom')
    register('createdTo')

    return () => {
      unregister('event')
      unregister('equipment')
      unregister('type')
      unregister('createdFrom')
      unregister('createdTo')
    }
  }, [register, unregister])

  const actions = useMemo(
    () => [
      {
        label: 'Visualizar',
        Icon: 'view',
        handler: (event) => {
          if (event.occurrenceId) {
            const url = buildPath({
              path: paths.occurrence.attendance,
              params: { occurrenceId: event.occurrenceId },
            })

            if (openInNewTab) {
              window.open(url, '_blank')
            } else {
              navigate(url, {
                state: {
                  readonly: true,
                },
              })
            }
          }
        },
        isVisible: (event) => !!event.occurrenceId,
      },
    ],
    [],
  )

  const handleResetPagination = () => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: '0',
    })
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'event',
        header: 'Evento',
        cell: (info) => <EventDescription {...info.getValue()} />,
      }),
      columnHelper.accessor((row) => row, {
        size: 68,
        header: 'Origem',
        cell: (info) => <OriginCell row={info.row} />,
      }),
      columnHelper.accessor('device', {
        header: 'Equipamento',
        cell: (info) => {
          const device = info.getValue()
          const command = info.row.original.command

          return getEventDevice(
            getNormalizedDeviceTypeCode(device?.deviceTypeCode),
            command?.id,
          )
        },
      }),
      columnHelper.accessor('partitions', {
        size: 63,
        header: 'Partições',
        cell: (info) => <PartitionsCell row={info.row} />,
      }),
      columnHelper.accessor('datetime', {
        size: 93,
        header: 'Data e hora',
        cell: (info) => dateNowWithSeconds(info.getValue()),
      }),

      columnHelper.accessor((row) => row, {
        id: 'action',
        header: '',
        size: 42,
        cell: (info) => {
          const value = info.getValue()
          const visibleActions = actions.filter((action) =>
            action.isVisible?.(value),
          )

          if (!visibleActions?.length) {
            return null
          }

          return visibleActions.length > 1 ? (
            <Droplist
              trigger={<Icon name="menu-kebab" style={{ cursor: 'pointer' }} />}
            >
              <ul className={styles.dropListItem}>
                {visibleActions.map((action) => (
                  <li key={action.label} onClick={() => action.handler(value)}>
                    <Icon name={action.Icon} style={{ cursor: 'pointer' }} />
                    {action.label}
                  </li>
                ))}
              </ul>
            </Droplist>
          ) : (
            <Icon
              name={visibleActions[0].Icon}
              onClick={() => visibleActions[0].handler(value)}
              style={{ cursor: 'pointer' }}
            />
          )
        },
      }),
    ],
    [columnHelper, actions],
  )

  const table = useReactTable({
    columns,
    data: data?.data || [],
    getCoreRowModel: getCoreRowModel(),
  })

  const handleRender = (
    data: unknown[] | undefined,
    isError: boolean,
    isFetching: boolean,
  ) => {
    if (!isFetching && data?.length) {
      return 'view'
    }

    if (isFetching) {
      return 'loading'
    }

    if (isError) {
      return 'error'
    }

    return 'empty'
  }

  return (
    <>
      <div className={styles.wrapper}>
        {
          {
            view: (
              <div className={styles.tableContainer}>
                <Table.Root>
                  <Table.Header>
                    {table?.getHeaderGroups().map((headerGroup) => (
                      <Table.Row key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <Table.ResizableColumn
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{ width: header.column.getSize() }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </Table.ResizableColumn>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Header>

                  <Table.Body className={styles.tbody}>
                    {table?.getRowModel().rows.map((row) => (
                      <Fragment key={row.id}>
                        <Table.Row>
                          {row.getVisibleCells().map((cell) => (
                            <Table.Cell
                              key={cell.id}
                              className={styles[cell.column.id]}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      </Fragment>
                    ))}
                  </Table.Body>
                </Table.Root>
              </div>
            ),
            empty: <EmptyState type="EmptyDataFromBFF" />,
            error: <EmptyState type="ErrorToLoadInfos" />,
            loading: (
              <div className={styles.loader}>
                <Loader />
              </div>
            ),
          }[handleRender(data?.data, isError, isFetching)]
        }
        <Pagination
          className={`${styles.pagination} ${filtersProps ? styles.paginationWrapper : ''}`}
          totalElements={data?.totalElements}
        />
      </div>
      {filtersProps && (
        <FormProvider {...form}>
          <EventHistoryFilters
            {...filtersProps}
            onApplyFilters={handleSubmit(() => {
              setFilters(watch())
              filtersProps.hide()
              fetchEvents()
              handleResetPagination()
            })}
          />
        </FormProvider>
      )}
    </>
  )
}
